/*
* $ lightbox_me
* By: Buck Wilson
* Version : 2.3
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

(function($) {

    $.fn.lightbox_me = function(options) {

        return this.each(function() {
            var
                opts = $.extend({}, $.fn.lightbox_me.defaults, options),
                $overlay = $(),
                $self = $(this),
                $iframe = $('<iframe id="foo" style="z-index: ' + (opts.zIndex + 1) + ';border: none; margin: 0; padding: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0; filter: mask();"/>'),
                ie6 = ($.browser.msie && $.browser.version < 7);

            if (opts.showOverlay) {
                //check if there's an existing overlay, if so, make subequent ones clear
               var $currentOverlays = $(".js_lb_overlay:visible");
                if ($currentOverlays.length > 0){
                    $overlay = $('<div class="lb_overlay_clear js_lb_overlay"/>');
                } else {
                    $overlay = $('<div class="' + opts.classPrefix + '_overlay js_lb_overlay"/>');
                }
            }

            /*----------------------------------------------------
               DOM Building
            ---------------------------------------------------- */
            if (ie6) {
                var src = /^https/i.test(window.location.href || '') ? 'javascript:false' : 'about:blank';
                $iframe.attr('src', src);
                $(opts.container).append($iframe);
            } // iframe shim for ie6, to hide select elements
            $(opts.container).append($self.hide()).append($overlay);


            /*----------------------------------------------------
               Overlay CSS stuffs
            ---------------------------------------------------- */

            // set css of the overlay
            if (opts.showOverlay) {
                setOverlayHeight(); // pulled this into a function because it is called on window resize.
                $overlay.css({ position: 'absolute', width: '100%', top: 0, left: 0, right: 0, bottom: 0, zIndex: (opts.zIndex + 2), display: 'none' });
				if (!$overlay.hasClass('lb_overlay_clear')){
                	$overlay.css(opts.overlayCSS);
                }
            }

            /*----------------------------------------------------
               Animate it in.
            ---------------------------------------------------- */
               //
            if (opts.showOverlay) {
                $overlay.fadeIn(opts.overlaySpeed, function() {
                    setSelfPosition();
                    $self[opts.appearEffect](opts.lightboxSpeed, function() { setOverlayHeight(); setSelfPosition(); opts.onLoad()});
                });
            } else {
                setSelfPosition();
                $self[opts.appearEffect](opts.lightboxSpeed, function() { opts.onLoad()});
            }

            /*----------------------------------------------------
               Hide parent if parent specified (parentLightbox should be jquery reference to any parent lightbox)
            ---------------------------------------------------- */
            if (opts.parentLightbox) {
                opts.parentLightbox.fadeOut(200);
            }


            /*----------------------------------------------------
               Bind Events
            ---------------------------------------------------- */

            $(window).on('resize', setOverlayHeight)
                     .on('resize', setSelfPosition)
                     .on('scroll', setSelfPosition);

            $(window).on('keyup.lightbox_me', observeKeyPress);

            if (opts.closeClick) {
                $overlay.on('click', function(e) { closeLightbox(); e.preventDefault(); });
            }
            $self.on('click', opts.closeSelector, function(e) {
                closeLightbox(); e.preventDefault();
            });
            $self.on('close', closeLightbox);
            $self.on('reposition', setSelfPosition);



            /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */


            /*----------------------------------------------------
               Private Functions
            ---------------------------------------------------- */

            /* Remove or hide all elements */
            function closeLightbox() {
                var s = $self[0].style;
                if (opts.destroyOnClose) {
                    $self.add($overlay).remove();
                } else {
                    $self.add($overlay).hide();
                }

                //show the hidden parent lightbox
                if (opts.parentLightbox) {
                    opts.parentLightbox.fadeIn(200);
                }

                $iframe.remove();

				// clean up events.
                $self.off('click', opts.closeSelector);

                $(window).off('reposition', setOverlayHeight);
                $(window).off('reposition', setSelfPosition);
                $(window).off('scroll', setSelfPosition);
                $(window).off('keyup.lightbox_me');
                opts.onClose();
            }


            /* Function to bind to the window to observe the escape/enter key press */
            function observeKeyPress(e) {
                if((e.keyCode == 27 || (e.DOM_VK_ESCAPE == 27 && e.which==0)) && opts.closeEsc) closeLightbox();
            }


            /* Set the height of the overlay
                    : if the document height is taller than the window, then set the overlay height to the document height.
                    : otherwise, just set overlay height: 100%
            */
            function setOverlayHeight() {
                /**
                 * The try/catch block was added as part of SE-18603. The reason for this code is that we've determined
                 * that there are cases where a modal is being created while the window is closing; this caused calls to
                 * `$(window).height()` or `$(document).height()` to throw an error. We don't want to log these errors
                 * to the server, we're going to bail early instead.
                 */
                var isWindowSmaller;
                try {
                    isWindowSmaller = $(window).height() < $(document).height();
                } catch (e) {
                    return;
                }

                if (isWindowSmaller) {
                    $overlay.css({
                        height: $(document).height() + 'px'
                    });
                    $iframe.css({
                        height: $(document).height() + 'px'
                    });
                } else {
                    $overlay.css({
                        height: '100%'
                    });
                    if (ie6) {
                        $('html,body').css('height', '100%');
                        $iframe.css('height', '100%');
                    } // ie6 hack for height: 100%; TODO: handle this in IE7
                }
            }

            /* Get value of the passed in parameter, if it's function, return
            the value from it.
            */
            function getValue(value){
                return $.isFunction(value) ? value() : value;
            }

            /* Set the position of the modal'd window ($self)
                    : if $self is taller than the window, then make it absolutely positioned
                    : otherwise fixed
            */
            function setSelfPosition() {
                var s = $self[0].style;

                // reset CSS so width is re-calculated for margin-left CSS
                $self.css({left: '50%', marginLeft: ($self.outerWidth() / 2) * -1,  zIndex: (opts.zIndex + 3) });


                /* we have to get a little fancy when dealing with height, because lightbox_me
                    is just so fancy.
                 */

                // if the height of $self is bigger than the window and self isn't already position absolute
                if (($self.height() >= $(window).height()) && ($self.css('position') != 'absolute') || ie6) {
                    // we are going to make it positioned where the user can see it, we use the modalCSS as a hard limit
                    // so that it won't be pushed up behind the header. We could do a bit more fanciness if neccessary in the future.
                    var top = (opts.modalCSS && opts.modalCSS.top) ? parseInt(getValue(opts.modalCSS.top)) : 0;
                    var topOffset = $(document).scrollTop() + top;

                    $self.css({position: 'absolute', top: topOffset + 'px', marginTop: 0})
                    if (ie6) {
                        s.removeExpression('top');
                    }
                } else if ($self.height() < $(window).height()) {
                    //if the height is less than the window height, then we're gonna make this thing position: fixed.
                    // in ie6 we're gonna fake it.
                    if (ie6) {
                        s.position = 'absolute';
                        if (opts.centered) {
                            $self.css({ position: 'absolute', top: '50%', marginTop: ($self.outerHeight() / 2) * -1})
                        } else {
                            var top = (opts.modalCSS && opts.modalCSS.top) ? parseInt(getValue(opts.modalCSS.top)) : 0;
                            $self.css({ position: 'absolute', top: '50%', marginTop: ($self.outerHeight() / 2) * -1 + top})
                        }
                    } else {
                        if (opts.centered) {
                            $self.css({ position: 'fixed', top: '50%', marginTop: ($self.outerHeight() / 2) * -1})
                        } else {
                            $self.css({ position: 'fixed'}).css(opts.modalCSS);
                        }

                    }
                }
            }

        });



    };

    $.fn.lightbox_me.defaults = {

        // container
        container: 'body',

        // animation
        appearEffect: "fadeIn",
        appearEase: "",
        overlaySpeed: 250,
        lightboxSpeed: 300,

        // close
        closeSelector: ".close",
        closeClick: true,
        closeEsc: true,

        // behavior
        destroyOnClose: false,
        showOverlay: true,
        parentLightbox: false,

        // callbacks
        onLoad: function() {},
        onClose: function() {},

        // style
        classPrefix: 'lb',
        zIndex: 999,
        centered: false,
        modalCSS: {top: '0px'},
        overlayCSS: {background: 'black', opacity: .3}
    }
})(jQuery);
